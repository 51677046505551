
<script setup lang="ts">
import { PostSmall } from '@/api/models';
import { Strapi4ResponseData } from '@nuxtjs/strapi/dist/runtime/types';
import { DateTime } from 'luxon';
defineProps<{ article: Strapi4ResponseData<PostSmall> }>();
const localePath = useLocalePath();
const runtimeConfig = useRuntimeConfig();
const { locale, t } = useI18n();
</script>


<template>
    <article
        class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-hidden mb-4 z-20"
        :style="{'view-transition-name': `article-${article.attributes.slug}-transition`}">
        <div v-if="$props.article.attributes.thumbnail.data" class="h-72 overflow-hidden"
            :style="{ 'background-color': article.attributes.thumbnail_bg_color }">
            <NuxtLink :to="localePath('/post/' + article.attributes.slug)" class="w-full h-full block" :aria-label="article.attributes.title">
                <img :src="runtimeConfig.public.strapi.url + $props.article.attributes.thumbnail.data.attributes.url"
                    class="w-auto max-h-full mx-auto hover:scale-150 transition-transform aspect-auto" :alt="article.attributes.title"/>
            </NuxtLink>
        </div>
        <div class="p-5">

            <div class="flex gap-2 mb-2">
                <div>
                    <object
                        :alt="article.attributes.author.data.attributes.name + ' Profile photo'"
                        :data="'https://www.gravatar.com/avatar/' + article.attributes.author.data.attributes.name + '?d=retro'"
                        type="image/webp" class="block fit-content rounded-lg overflow-hidden w-10 h-10">
                        <img :src="''" :alt="article.attributes.author.data.attributes.name + ' Profile photo'">
                    </object>
                </div>
                <div class="flex flex-col align-middle">
                    <span class="font-bold text-gray-600 inline-block">
                        {{ article.attributes.author.data.attributes.name }}
                    </span>
                    <small class="text-gray-500">
                        {{ t("post.on") }} 
                        {{ DateTime.fromISO(article.attributes.publishedAt).toLocaleString(DateTime.DATE_HUGE, { locale }) }}
                    </small>
                </div>

            </div>
            <hr class="mb-2 mt-4" />
            <NuxtLink :to="localePath('/post/' + article.attributes.slug)">
                <h2 class="mb-2 text-5xl font-bold tracking-tight text-gray-900 dark:text-white">{{
                    article.attributes.title }}</h2>
            </NuxtLink>

            <ul class="flex flex-row flex-wrap mb-2 gap-2">
                <li v-for="tag in article.attributes.tags.data">
                    <NuxtLink :to="localePath('/tag/' + tag.attributes.tagName)" class="hover:underline">
                        <span :style="{ 'color': tag.attributes.tagColor }">#</span>{{
                            tag.attributes.tagName }}
                    </NuxtLink>
                </li>
            </ul>

            <p class="mt-2 mb-4 text-gray-800">{{ article.attributes.description }}</p>

            <NuxtLink :to="localePath('/post/' + article.attributes.slug)"
                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 ">

                {{ t("home.read-more") }}
                <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
            </NuxtLink>
        </div>
    </article>
</template>